export default {
  discounts: 'الخصومات',
  discount: 'خصم',
  numberOfUses: 'عدد الاستخدامات',
  minOrderDiscount: 'الحد الأدنى للطلب',
  numberOfUsesForUser: 'عدد مرات استخدام الكود للمستخدم الواحد',
  startDate: 'تاريخ البداية',
  endDate: 'تاريخ النهاية',
  orderValue: 'قيمة الطلب',
  canBeUsedWithOffers: 'يمكن استخدامه مع العروض',
  addDiscount: 'إضافة خصم'
}
