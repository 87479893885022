export default {
  discounts: 'Discounts',
  discount: 'discount',
  numberOfUses: 'Number of uses',
  minOrderDiscount: 'Minimum discount value',
  numberOfUsesForUser: 'Number of uses for each user',
  startDate: 'Start date',
  endDate: 'End date',
  orderValue: 'Order value',
  canBeUsedWithOffers: 'Can be used with offers',
  addDiscount: 'Add discount'
}
