import validation from './validation/validation'
import admins from './admins/admins'
import main from './main/main'
import auth from './auth/auth'
import dashboard from './dashboard/dashboard'
import products from './products/products'
import withdraw from './withdraw/withdraw'
import orders from './orders/orders'
import offers from './offers/offers'
import discounts from './discounts/discounts'
import roles from './roles/roles'
export default {
  validation,
  admins,
  main,
  auth,
  dashboard,
  products,
  withdraw,
  orders,
  offers,
  discounts,
  roles
}
