export default {
  Withdrawals: 'عمليات السحب',
  pending: 'معلقة',
  accepted: 'مقبولة',
  rejected: 'مرفوضة',
  denied: 'مرفوضة',
  withdrawableRevenue: 'ايرادات قابله للسحب',
  addNewWithdrawal: 'اضافة عملية سحب جديدة',
  paymentMethod: 'طريقة الدفع',
  paymentType: 'نوع الدفع',
  statuses: {
    completed: 'مكتملة',
  },
  types: {
    deposit: 'ايداع',
    withdraw: 'سحب',
    purchase: 'شراء',
  },
  methods: {
    card: 'بطاقة ائتمانية',
    wallet: 'محفظة الكترونية',
  },
};
