export default {
  english: 'English',
  arabic: 'Arabic',
  login: 'Login',
  cart: 'Cart',
  city: 'city',
  country: 'country',
  title: 'title',
  code: 'code',
  region: 'region',
  image: 'Image',
  images: 'Images',
  sar: 'Saudi riyal',
  mainImage: 'Main image',
  uploadMainImages: 'Upload main image',
  name: 'Name',
  description: 'Description',
  discount_info: 'Discount information',
  slug: 'Slug',
  add: 'Add',
  id: 'ID',
  save: 'Save',
  back: 'Back',
  search: 'Search',
  areas: 'Areas',
  area: 'Area',
  amount: 'Amount',
  price: 'price',
  currency: '{num} SR',
  item: 'item',
  delete: 'Delete',
  cancel: 'Cancel',
  customerName: 'Customer name',
  phone: 'Phone',
  address: 'Address',
  enterName: 'Enter name',
  deleteConfirmation: 'Are you sure you want to delete this item?',
  generalInformation: 'General information',
  total_amount: 'Total amount',
  sub_total: 'Sub total',
  final_price: 'Final price',
  added_value: 'Added value',
  tax_amount: 'Tax amount',
  shipping_amount: 'Shipping amount',
  discount_amount: 'Discount amount',
  payment_method: 'Payment method',
  created_at: 'Created at',
  quantity: 'Quantity',
  view: 'View',
  changeStatus: 'Change status',
  download: 'Download',
  thereAreNoData: 'There are no data',
  merchantInfo: 'Merchant information',
  merchantStore: 'Merchant store',
  merchantDetails: 'Merchant details',
  nationalId: 'National ID',
  merchantLinks: 'Merchant links',
  about: 'About',
  aboutStore: 'About store',
  branches: 'Branches',
  branch: 'Branch',
  email: 'Email',
  categories: 'Categories',
  category: 'Category',
  merchantLink: 'Merchant link',
  storeLink: 'Store link',
  addAnotherPlatform: 'Add Another platform',
  vat_no: 'VAT number',
  termsAndPolicy: 'Terms and policy',
  status: {
    status: 'Status',
    changeStatus: 'Change status',
    all: 'All',
    active: 'Active',
    deactive: 'Deactive',
    pending: 'Pending',
    preparation: 'In preparation',
    on_delivery: 'On delivery',
    refused: 'Refused',
    success: 'Success',
    published: 'Published',
    hidden: 'Hidden',
    in_progress: 'In progress',
    preparing: 'Preparing',
    shipping_to_order: 'Shipping to order',
    rejected: 'Rejected',
    delivered: 'Delivered',
    and: 'and',
  },
  sidebar: {
    dashboard: 'dashboard',
    products: 'products',
    orders: 'orders',
    offers: 'offers',
    discount: 'discount',
    withdraw: 'withdraw',
    reviews: 'reviews',
    branches: 'branches',
    employees: 'employees',
    categories: 'categories',
    collection: 'collection',
    processing: 'processing',
    settings: 'settings',
    collections: 'collections',
    support: 'support',
    logout: 'logout',
    transactions: 'transactions'
  },
  type: 'type',
  ar: {
    name: 'name in arabic',
    description: 'description in arabic',
    enterName: 'enter name in arabic'
  },
  en: {
    name: 'name in english',
    description: 'description in english',
    enterName: 'enter name in english'
  },
  chooseStatus: 'choose status',
  basic_info: 'basic information',
  keyWords: 'key words'
};
