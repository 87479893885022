// Layout
const dashboardLayout = () => import('@/layouts/DashboardLayout/dashboardLayout.vue')

const roles = () => import('./views/index')
const addRole = () => import('./views/addRole')

// start Routes
export default [
  {
    path: '/roles',
    name: 'roles',
    component: dashboardLayout,
    children: [{
      path: '',
      name: 'roles',
      meta: {
        name: 'roles',
        parentName: 'marketplace',
        userType: '',
        permission: 'roles:find'
      },
      component: roles
    },
    {
      path: 'add-roles',
      name: 'addRole',
      meta: {
        name: 'addRole',
        parentName: 'marketplace',
        userType: '',
        permission: 'roles:create'
      },
      component: addRole
    },
    {
      path: 'edit-roles/:id',
      name: 'editRole',
      meta: {
        name: 'editRole',
        parentName: 'marketplace',
        userType: '',
        permission: 'roles:update'
      },
      component: addRole
    }
    ]
  }
]
