export default {
  english: 'الأنجليزية',
  arabic: 'العربية',
  login: 'تسجيل الدخول',
  cart: 'سلة المشتريات',
  city: 'المدينة',
  country: 'الدولة',
  add: 'اضافه',
  region: 'المنطقه',
  id: 'الرقم',
  image: 'الصوره',
  about: 'عنا',
  aboutStore: 'عن المتجر',
  amount: 'الكمية',
  images: 'الصور',
  mainImage: 'الصورة الرئيسية',
  name: 'الاسم',
  vat_no: 'الرقم الضريبي',
  description: 'الوصف',
  termsAndPolicy: 'الشروط والسياسات',
  slug: 'الرابط المختصر',
  type: 'النوع',
  save: 'حفظ',
  back: 'رجوع',
  search: 'بحث',
  price: 'السعر',
  currency: '{num} ر.س',
  generalInformation: 'معلومات عامة',
  total_amount: 'المبلغ الإجمالي',
  sub_total: 'المجموع الفرعي',
  final_price: 'السعر النهائي',
  added_value: 'القيمة المضافة',
  tax_amount: 'قيمة الضريبة',
  shipping_amount: 'قيمة الشحن',
  discount_amount: 'مقدار الخصم',
  area: 'المنطقة',
  payment_method: 'طريقة الدفع',
  created_at: 'أنشئت في',
  quantity: 'الكمية',
  view: 'عرض',
  changeStatus: 'تغيير الحالة',
  download: 'تحميل',
  thereAreNoData: 'لا يوجد بيانات',
  uploadMainImages: 'رفع الصورة الرئيسية',
  title: 'العنوان',
  code: 'الكود',
  deleteConfirmation: 'هل أنت متأكد من حذف هذا العنصر؟',
  delete: 'حذف',
  cancel: 'إلغاء',
  customerName: 'اسم العميل',
  phone: 'رقم الهاتف',
  address: 'العنوان',
  enterName: 'أدخل الاسم',
  nationalId: 'الرقم القومي',
  categories: 'الاقسام',
  category: 'القسم',
  email: 'البريد الإلكتروني',
  sar: 'ريال سعودي',
  discountInfo: 'معلومات الخصم',
  merchantInfo: 'معلومات التاجر',
  merchantStore: 'متجر التاجر',
  merchantLinks: 'روابط التاجر',
  merchantLink: 'رابط التاجر',
  storeLink: 'رابط المتجر',
  addAnotherPlatform: 'اضافة منصة اخرى',
  merchantDetails: 'تفاصيل التاجر',
  storeDetails: 'تفاصيل المتجر',
  item: 'عنصر',
  areas: 'المناطق',
  and: 'و',
  status: {
    status: 'الحالة',
    changeStatus: 'تغير الحالة',
    all: 'الكل',
    active: 'مفعل',
    deactive: 'غير مفعل',
    pending: 'جارى الطلب',
    preparation: 'جارى التحضير',
    on_delivery: 'جارى الشحن',
    refused: 'تم الرفض',
    success: 'تم الاستلام',
    published: 'منشور',
    hidden: 'مخفي',
    in_progress: 'قيد التنفيذ',
    preparing: 'جاري التحضير',
    shipping_to_order: 'جاري الشحن',
    rejected: 'مرفوض',
    delivered: 'تم التوصيل'
  },
  sidebar: {
    dashboard: 'لوحة التحكم',
    products: 'المنتجات',
    orders: 'طلبات',
    offers: 'العروض',
    withdraw: 'العمليات المالية',
    reviews: 'التعليقات',
    branches: 'الفروع',
    employees: 'الموظفين',
    categories: 'الأقسام',
    amount: 'المبلغ',
    discount: 'خصومات',
    collection: 'المجموعات',
    processing: 'فى الإنتظار',
    settings: 'اعدادات المتجر',
    collections: 'مجموعات',
    support: 'الدعم الفني',
    logout: 'تسجيل الخروج',
    transactions: 'المعاملات'
  },
  branches: 'الفروع',
  branch: 'فرع',
  ar: {
    name: 'الاسم بالعربية',
    description: 'الوصف بالعربية',
    enterName: 'أدخل الاسم بالعربية'
  },
  en: {
    name: 'الاسم بالانجليزية',
    description: 'الوصف بالانجليزية',
    enterName: 'أدخل الاسم بالانجليزية'
  },
  chooseStatus: 'اختر الحالة',
  basic_info: 'المعلومات الأساسية',
  keyWords: 'الكلمات الدلالية',
  active: 'مفعل',
  deactive: 'غير مفعل'
};
