import invoice from './views/index.vue';

export default [
  {
    path: '/invoice/:id',
    name: 'invoice',
    meta: {
      requiredAuth: true
    },
    component: invoice,
  }
];