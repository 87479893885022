export default {
  orders: 'الطلبات',
  orderDetails: 'تفاصيل الطلب',
  order_num: 'رقم الطلب',
  number_of_products: 'عدد المنتجات',
  total_price: 'السعر الإجمالي',
  order_date: 'تاريخ الطلب',
  wallet: 'المحفظة',
  card: 'بطاقة ائتمان',
};
