import Vue from 'vue'
import moment from 'moment'

Vue.filter('reverse', function (value) {
  // slice to make a copy of array, then reverse the copy
  return value.slice().reverse()
})

Vue.filter('formatDateAgo', function (value) {
  if (value) {
    return moment(String(value)).fromNow()
  }
})
Vue.filter('formatDateTime', function (value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY - hh:mm:ss A')
  } else {
    return '---'
  }
})
Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  } else {
    return '---'
  }
})
Vue.filter('formatTime', function (value) {
  if (value) {
    return moment(String(value)).format('hh:mm:ss A')
  } else {
    return '---'
  }
})

export function formatNumber (value) {
  return Number(value).toLocaleString()
}
