export default {
  products: 'المنتجات',
  productsNumber: 'عدد المنتجات',
  mainImage: 'الصوره الرئيسية',
  name: 'الاسم',
  images: 'الصور',
  price: 'السعر',
  quantity: 'الكمية',
  date: 'التاريخ',
  status: 'الحالة',
  control: 'التحكم',
  addProduct: ' إضافة منتج',
  mainInfo: ' معلومات أساسية',
  productDetails: 'تفاصيل المنتج',
  transportation: ' الشحن',
  productAttributes: 'سمات المنتج',
  addAttributes: 'إضافة سمة جديدة',
  productRelated: '  منتجات ذات صلة',
  mainQuestions: 'الاسئلة الشائعة الخاصة بالمنتج',
  addQuestions: 'إضافة سؤال',
  searchWords: ' كلمات دالة',
  savePublish: 'حفظ و نشر',
  save: 'حفظ',
  publish: 'النشر',
  categories: {
    title: 'الاقسام',
    chooseCategory: 'اختر القسم',
    category: 'القسم',
  },
  branches: {
    title: 'الفروع',
    chooseBranches: 'اختر الفروع',
  },
  groups: 'المجموعات',
  taxes: 'الضريبة',
  chooseUnit: 'اختر الوحدة',
  unit: 'الوحدة'
};
