<template>
  <div>
    <section
      class="d-flex justify-content-between align-items-center border-bottom pb-2 mb-2"
    >
      <div class="dashboard-page-title position-relative">
        <h5 class="py-1 font-weight-bold position-relative">
          <slot></slot>
        </h5>
        <span class="text-gray font-size-12 numbersOfStores" v-if="numbers"
          >25 متجر</span
        >
      </div>
      <div v-if="showMainActions">
        <template v-if="dropdownList.length">
          <b-dropdown
            v-for="(item, index) in dropdownList"
            :key="index"
            variant="outline-primary"
            :text="
              item.options.length &&
              item.options.find((el) => el.value === item.selected)
                ? item.options.find((el) => el.value === item.selected).title
                : '---'
            "
            class="m-md-2 actionButton"
          >
            <b-dropdown-item
              v-for="(option, i) in item.options"
              :key="i"
              @click.prevent="
                dropdownChanged({ index: index, value: option.value })
              "
              :active="
                option.value ==
                (item.options.find((el) => el.value === item.selected)
                  ? item.options.find((el) => el.value === item.selected).value
                  : '')
              "
              >{{ option.title }}</b-dropdown-item
            >
          </b-dropdown>
        </template>
        <b-button
          v-if="showAddBtn"
          @click.prevent="$emit('addEvent')"
          variant="primary"
          class="px-5 py-2"
        >
          {{ $t("main.add") }}
        </b-button>
      </div>
    </section>
    <div class="d-flex justify-content-between mb-3">
      <div
        v-if="showSearch"
        class="position-relative pr-4 bg-white p-0 m-0 searchInput iq-border-radius-5 w-20 overflow-hidden"
      >
        <i class="las la-search position-absolute searchInputIcon"></i>
        <input
          type="text"
          class="form-control border-0 w-200px"
          :value="value"
          :placeholder="$t('main.search')"
          @input="$emit('input', $event.target.value)"
        />
      </div>
    </div>
  </div>
</template>

<script>
/*
dropdownList = [
  {
    selected: 'active',
    options: [
      { title: 'All', value: '' },
      { title: 'Active', value: 'active' },
      { title: 'Deactive', value: 'deactive' }
    ]
  }
]
*/
export default {
  props: {
    value: { default: "", type: String },
    placeholder: { default: "", type: String },
    numbers: { default: "", type: String },
    showSearch: { default: false, type: Boolean },
    showMainActions: { default: true, type: Boolean },
    showAddBtn: { default: true, type: Boolean },
    dropdownList: { default: () => [], type: Array },
  },
  methods: {
    dropdownChanged(data) {
      this.dropdownList[data.index].selected = data.value;
      this.$emit("dropdownChanged", data);
    },
  },
};
</script>

<style lang="scss">
.dashboard-page-title {
  h5 {
    border-inline-start: 2px solid #000;
    padding-inline-start: 16px;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translate(19px, -50%);
      width: 5px;
      height: 12px;
      border-radius: 10px;
      background: var(--iq-warning);
    }
  }
}
.actionButton {
  min-width: 160px;
  height: 39px;
  > button {
    padding: 0.375rem 2rem !important;
    ::after {
      left: 14px !important;
      margin: 0 !important;
    }
  }
}
.numbersOfStores {
  position: absolute;
  bottom: -16px;
  right: 20px;
}
.searchInput {
  height: 47px;
}
.searchInputIcon {
  top: 15px;
  right: 10px;
}
</style>
