// Layout
const dashboardLayout = () => import('@/layouts/DashboardLayout/dashboardLayout.vue');

const allProducts = () => import('./views/index');
const addProduct = () => import('./views/addProduct');

// start Routes
export default [
  {
    path: '/products',
    name: 'products',
    component: dashboardLayout,
    children: [ {
      path: '',
      name: 'products',
      meta: {
        name: 'products',
        parentName: 'marketplace',
        userType: '',
        permission: 'products:find'
      },
      component: allProducts
    }, {
      path: 'add-product',
      name: 'addProduct',
      meta: {
        name: 'addProduct',
        parentName: 'marketplace',
        userType: '',
        permission: 'products:create'
      },
      component: addProduct
    },
    {
      path: 'show-product/:id',
      name: 'showProduct',
      props: true,
      meta: {
        name: 'showProduct',
        parentName: 'marketplace',
        userType: '',
        permission: 'products:findOne'
      },
      component: addProduct
    },
    {
      path: 'edit-product/:id',
      name: 'editProduct',
      props: true,
      meta: {
        name: 'editProduct',
        parentName: 'marketplace',
        userType: '',
        permission: 'products:update'
      },
      component: addProduct
    }
    ]
  },

];
