export default {
  dashboard: 'لوحة التحكم',
  profits: 'الأرباح',
  expectedProfits: 'الإيرادات المتوقعة',
  retainedProfits: 'الإيرادات المحتجزة',
  withdrawableProfits: 'إيرادات قابلة للسحب',
  totalIncome: 'إجمالي المبيعات',
  walletBalance: 'رصيد المحفظة',
  totalOrders: 'إجمالى الطلبات',
  totalCategories: 'إجمالى الاقسام',
  totalProducts: 'إجمالى المنتجات'
}
