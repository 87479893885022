export default {
  orders: 'Orders',
  orderDetails: 'Order details',
  order_num: 'Order number',
  number_of_products: 'Number of products',
  total_price: 'Total price',
  order_date: 'Order date',
  wallet: 'Wallet',
  card: 'credit card',
};
