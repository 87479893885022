import Vue from 'vue';
import VueRouter from 'vue-router';
import auth from '@/modules/auth/auth.routes';
import dashboardRoutes from '@/modules/dashboard/dashboard.routes';
import productRoutes from '@/modules/products/products.routes';
import orderRoutes from '@/modules/orders/order.routes';
import offersRoutes from '@/modules/offers/offers.routes';
import branchesRoutes from '@/modules/branches/branches.routes';
import employeesRoutes from '@/modules/employees/employees.routes';
import discountsRoutes from '@/modules/discounts/discounts.routes';
import withdrawRoutes from '@/modules/withdraw/withdraw.routes';
import reviewsRoutes from '@/modules/reviews/reviews.routes';
import settingsRoutes from '@/modules/settings/settings.routes';
import settingStoreRoutes from '@/modules/storeSetting/settings.routes';
import completeInformationRoutes from '@/modules/completeInformation/completeInformation.routes';
import processingRoutes from '@/modules/processing/processing.routes';
import transactionRoutes from '../modules/transaction/transaction.routes';
import rolesRoutes from '../modules/roles/roles.routes';
import invoiceRoutes from '../modules/invoice/invoice.routes';
/* Extra Pages */
const ErrorPage = () => import('../views/Pages/ErrorPage');

Vue.use(VueRouter);

const routes = [
  ...auth,
  ...dashboardRoutes,
  ...productRoutes,
  ...orderRoutes,
  ...offersRoutes,
  ...branchesRoutes,
  ...employeesRoutes,
  ...discountsRoutes,
  ...withdrawRoutes,
  ...reviewsRoutes,
  ...settingsRoutes,
  ...settingStoreRoutes,
  ...completeInformationRoutes,
  ...processingRoutes,
  ...transactionRoutes,
  ...rolesRoutes,
  ...invoiceRoutes,
  {
    path: '*',
    name: 'errorPage',
    meta: { name: 'dashboard', userType: 'both' },
    component: ErrorPage
  },
  {
    path: '/forbidden',
    name: 'forbidden',
    meta: { name: 'dashboard', userType: 'both' },
    component: () => import('../views/Pages/ForbiddenPage')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes,
  linkActiveClass: 'router-link-exact-active',
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

const checkStoreStatus = (to, from, next) => {
  const storeStatus = JSON.parse(localStorage.getItem('userInfo'))?.store?.status;
  const statuses = [ 'pending', 'processing' ];
  if
    (to.name !== 'processing' && to.name !== 'store-setting' && statuses.includes(storeStatus)) {
    console.log('storeStatus', storeStatus);
    return next({ name: 'processing' });
  }
  else if (storeStatus == 'publish' && to.name == 'processing') {
    return next({ name: 'indexDash' });
  }
  else {
    next();
  }

};

router.beforeEach((to, from, next) => {
  if (localStorage.getItem('userInfo') === null && to.name !== 'login') {
    next({ name: 'login' });
  } else if (localStorage.getItem('userInfo') !== null && to.name === 'login') {
    next({ name: 'indexDash' });
  } else if (to.name !== 'forbidden' && to.meta.permission && !Vue.prototype.$perHelper(to.meta.permission)) {
    next({ name: 'forbidden' });
  } else {
    next();
  }
  checkStoreStatus(to, from, next);
});

export default router;
