<template>
  <div class="invoice my-2">
    <header class="text-center">
      <img :src="invoiceData.images.logo" alt="logo" />
      <h3>فاتورة ضريبة مبسطة</h3>
      <p>
        <span> تاريخ الطلب </span>

        <span class="order-date">
          {{ invoiceData.information.date }}
        </span>
      </p>
    </header>

    <main>
      <div class="store-info">
        <div class="store-name">
          <h4>اسم المتجر:</h4>
          <h6 class="">
            {{ invoiceData.sender.name }}
          </h6>
        </div>
        <div class="address-tax-number">
          <h4>رقم السجل التجاري:</h4>
          <h6 class="">
            {{ invoiceData.information["vat-number"] }}
          </h6>
        </div>

        <div class="store-address">
          <h4>العنوان:</h4>
          <h6 class="">
            {{ invoiceData.sender.address }}
          </h6>
        </div>
      </div>

      <table>
        <thead>
          <th>اسم المنتج</th>
          <th>العدد</th>
          <th>سعر الوحدة</th>
          <th>ضريبة القيمة المضافة</th>
          <th>الإجمالي</th>
        </thead>
        <tbody>
          <tr v-for="(product, idx) in invoiceData.products" :key="idx">
            <td>
              {{ product.name || "" }}
            </td>
            <td>
              {{ product.quantity }}
            </td>
            <td>
              {{ product.price }}
              ر.س
            </td>
            <td>
              {{ product.taxeRate }}
              ر.س
            </td>
            <td>
              {{ product["total-amount"] }}
              ر.س
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="4">إجمالي الضريبة:</td>
            <td>
              {{ invoiceData.information["total-tax"] }}
              رس
            </td>
          </tr>
          <tr>
            <td colspan="4">رسوم الشحن:</td>
            <td>
              {{ invoiceData.information["delivery-cost"] }}
              ر.س
            </td>
          </tr>
          <tr v-if="+invoiceData.information['discount-amount']">
            <td colspan="4">أجمالي الخصم:</td>
            <td>
              {{ invoiceData.information["discount-amount"] }}
              ر.س
            </td>
          </tr>

          <tr>
            <td class="font-bold" colspan="4">الإجمالي:</td>
            <td class="font-bold">
              {{ invoiceData.information["total-amount"] }}
              ر.س
            </td>
          </tr>
          <tr>
            <td colspan="4">طريقة الدفع:</td>
            <td class="text-green font-bold">
              {{ paymentMethod }}
            </td>
          </tr>
        </tfoot>
      </table>

      <div class="qr-image">
        <img :src="invoiceData.qrCodeImage" alt="qr" />
      </div>
      <div class="d-flex justify-content-end">
        <button class="print-invoice" @click="printInvoice">
          طباعة الفاتورة
        </button>
      </div>
    </main>
  </div>
</template>

<script>
import { marketPlace } from "@/axios";
export default {
  data() {
    return {
      invoiceData: {},
    };
  },
  computed: {
    paymentMethod() {
      return this.$t(
        `orders.${this.invoiceData.information[
          "payment-method-type"
        ].toLowerCase()}`
      );
    },
  },
  methods: {
    printInvoice() {
      window.print();
    },
    async getInvoiceData() {
      const { data } = await marketPlace().get(
        `/invoice/data/order/${this.$route.params.id}`
      );
      this.invoiceData = data;
    },
  },
  created() {
    this.getInvoiceData();
  },
};
</script>

<style lang="scss" >
body {
  &:has(.invoice) {
    background: white !important;
  }
}
html {
  &:has(.invoice) {
    direction: rtl;
  }
}
.invoice {
  max-width: 800px;
  margin: 0 auto;
  @media print {
    max-width: 600px;
    margin: 0;
  }
  header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  header .order-date {
    font-weight: bold;
  }

  main .store-info {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    background: #00786c;
    color: #fff;
    margin-top: 1rem;
    h4,
    h6 {
      color: #fff !important;
    }
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }

  th {
    border-bottom: 1px solid #dddddd !important;
  }
  tbody td,
  th {
    padding: 16px 8px !important;
  }

  tfoot td {
    padding: 8px !important;
  }
  tbody {
    tr {
      &:nth-child(even) {
        background-color: #f2f2f2 !important;
      }
      &:last-child {
        td {
          border-bottom: 1px solid #dddddd !important;
          margin-bottom: 1rem;
        }
      }
    }
  }
  .text-green {
    color: #30a851;
  }
  .font-bold {
    font-weight: bold;
  }
  .qr-image {
    text-align: center;
    margin-top: 1rem;
  }
  .print-invoice {
    display: block;
    margin: 0 auto;
    background: var(--iq-primary);
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    @media print {
      display: none;
    }
  }
}
</style>