import getters from './getters';
import actions from './actions';
import mutations from './mutations';
// TODO: hello world
const state = {
  lang: JSON.parse(localStorage.getItem('lang')) ?? { title: 'العربية', value: 'ar', image: require('../../assets/images/ibbil/Flag_of_Saudi_Arabia.png'), rtl: true },
  langOption: [
    { title: 'English', value: 'en', image: require('../../assets/images/small/flag-01.png'), rtl: false },
    { title: 'Arabic', value: 'ar', image: require('../../assets/images/ibbil/Flag_of_Saudi_Arabia.png'), rtl: true }
  ],
  activePage: {
    name: 'Dashboard'
  },
  layoutMode: {
    dark: false,
    rtl: true,
    sidebar: ''
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
