<template>
  <b-badge :class="`font-size-15 status--${filterStatus}`">
    <!-- <span >{{ status }}</span> -->
    <span >{{ objectTranslation ? $t(`${objectTranslation}.${status.toLowerCase()}`) : status }}</span>
  </b-badge>
</template>

<script>
export default {
  name: 'MainStatus',
  props: {
    status: { type: String, default: '' },
    objectTranslation: { type: String, default: '' }
  },
  /*  filters: {
    removeSpace(value) {
      return value.toLowerCase().replace(' ', '_') || this.data.toLowerCase().replaceAll(' ', '_')
    },
  }, */
  computed: {
    filterStatus () {
      if (this.status === 'pending') {
        return 'pending'
      } else if (this.status === 'in_progress') {
        return 'preparation'
      } else if (this.status === 'preparing') {
        return 'preparation'
      } else if (this.status === 'shipping_to_order') {
        return 'on_delivery'
      } else if (this.status === 'rejected') {
        return 'refused'
      } else if (this.status === 'delivered') {
        return 'success'
      }
      return this.status ? this.status.toLowerCase().replace(' ', '_') : ''
    }
  }
}
</script>
