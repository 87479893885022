// Layout
const dashboardLayout = () => import('@/layouts/DashboardLayout/dashboardLayout.vue');

const storeSetting = () => import('./views/index');

// start Routes
export default [
  {
    path: '/setting',
    name: 'setting',
    component: dashboardLayout,
    children: [ {
      path: 'store',
      name: 'store-setting',
      meta: {
        name: 'store-setting',
        parentName: 'marketplace',
        userType: 'seller',
        permission: 'stores:find'
      },
      component: storeSetting
    }
    ]
  }
];
