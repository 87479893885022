export default {
  offers: 'العروض',
  add: 'إضافة عرض',
  details: 'تفاصيل العرض',
  offerType: 'نوع العرض',
  offerStart: 'بداية العرض',
  offerEnd: 'نهاية العرض',
  typeStatus: {
    date: 'تاريخ',
    quantity: 'كمية'
  }
}
