export default {
  notValid: 'غير صحيح',
  required: 'إجباري',
  email: 'الحقل يجب أن يكون بريد إلكتروني',
  numeric: 'الحقل يجب أن يكون رقم',
  decimal: 'الحقل يجب أن يكون رقم صحيح أو عشري',
  min: 'الحد الادنى {num} حروف',
  max: 'الحد الاقصى {num} حروف',
  minValue: 'الحد الادنى {num}',
  maxValue: 'الحد الاقصى {num}',
  length: 'عدد الأحرف تجب أن تكون {num}',
  digits: 'يجب أن يكون الحقل رقميًا وأن يحتوي بالضبط على {num} أرقام',
  url: 'يجب أن يكون الحقل عنوان URL صالح'
};
