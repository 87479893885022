// Layout
const dashboardLayout = () => import('@/layouts/DashboardLayout/dashboardLayout.vue')

const allTransaction = () => import('./views/index')

// start Routes
export default [
  {
    path: '/transaction',
    name: 'transaction',
    component: dashboardLayout,
    children: [{
      path: '',
      name: 'transaction',
      meta: {
        name: 'transaction',
        parentName: 'marketplace',
        userType: '',
        permission: 'transactions:find'
      },
      component: allTransaction
    }
    ]
  }
]
