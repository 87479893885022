import * as rules from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import Vue from 'vue';
import i18n from '../i18n';

extend('decimal', {
  validate: (value, { decimals = '*', separator = '.' } = {}) => {
    if (value === null || value === undefined || value === '') {
      return {
        valid: false
      };
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value)
      };
    }
    // const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`
    // const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`)
    const regex = new RegExp('^[+-]?\\d+(\\.\\d+)?$');

    return {
      valid: regex.test(value)
    };
  },
  // message: 'The {_field_} field must contain only decimal values'
  message: i18n.t('validation.decimal')
});


extend('url', {
  validate: (value) => {

    const regex = new RegExp('^(http|https)://');
    return {
      valid: regex.test(value)
    };
  },
  message: i18n.t('validation.url')
});

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[ rule ], // copies rule configuration
    message: i18n.t(`validation.${ rule }`) // assign message
  });
});
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
