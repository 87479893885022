export default {
  offers: 'Offers',
  add: 'Add offer',
  details: 'Offer details',
  offerType: 'Offer type',
  offerStart: 'Start of offer',
  offerEnd: 'End of offer',
  typeStatus: {
    date: 'Date',
    quantity: 'Quantity'
  }
}
