// Layout
const dashboardLayout = () => import('@/layouts/DashboardLayout/dashboardLayout.vue')

const employees = () => import('./views/index')
const addEmployee = () => import('./views/addEmployee')

// start Routes
export default [
  {
    path: '/employees',
    name: 'employees',
    component: dashboardLayout,
    children: [{
      path: '',
      name: 'employees',
      meta: {
        name: 'employees',
        parentName: 'marketplace',
        userType: '',
        permission: 'users:find'
      },
      component: employees
    },
    {
      path: 'add-employees',
      name: 'addEmployee',
      meta: {
        name: 'addEmployee',
        parentName: 'marketplace',
        userType: '',
        permission: 'users:create'
      },
      component: addEmployee
    },
    {
      path: 'show-employee/:id',
      name: 'showEmployee',
      props: true,
      meta: {
        name: 'showEmployee',
        parentName: 'marketplace',
        userType: '',
        permission: 'users:findOne'
      },
      component: addEmployee
    },
    {
      path: 'edit-employee/:id',
      name: 'editEmployee',
      props: true,
      meta: {
        name: 'editEmployee',
        parentName: 'marketplace',
        userType: '',
        permission: 'users:update'
      },
      component: addEmployee
    }
    ]
  }
]
