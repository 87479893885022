// Layout
const dashboardLayout = () => import('@/layouts/DashboardLayout/dashboardLayout.vue')

const branches = () => import('./views/index')
const addBranch = () => import('./views/addBranch')

// start Routes
export default [
  {
    path: '/branches',
    name: 'branches',
    component: dashboardLayout,
    children: [{
      path: '',
      name: 'branches',
      meta: {
        name: 'branches',
        parentName: 'marketplace',
        userType: '',
        permission: 'branches:find'
      },
      component: branches
    },
    {
      path: 'add-branches',
      name: 'addBranch',
      meta: {
        name: 'addBranch',
        parentName: 'marketplace',
        userType: '',
        permission: 'branches:create'
      },
      component: addBranch
    },
    {
      path: 'edit-branches/:id',
      name: 'editBranch',
      meta: {
        name: 'editBranch',
        parentName: 'marketplace',
        userType: '',
        permission: 'branches:update'
      },
      component: addBranch
    }
    ]
  }
]
