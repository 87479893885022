export default {
  notValid: 'Not valid',
  required: 'Required',
  email: 'Field must email',
  numeric: 'Field must number',
  decimal: 'Field must whole number or decimal',
  min: 'Minimum is {num} characters',
  max: 'Maximum {num} characters',
  minValue: 'Minimum is {num}',
  maxValue: 'Maximum {num}',
  length: 'Number of characters must be {num}',
  digits: 'The field must be numeric and exactly contain {num} digits',
  url: 'The field must be a valid url'
};
