import axios from 'axios'
import { core } from '@/config/pluginInit'

export const apiHeader = () => {
  return {
    Authorization: localStorage.getItem('userToken') ? `Bearer ${JSON.parse(localStorage.getItem('userToken'))}` : null,
    Accept: 'application/json',
    ContentType: 'application/json',
    'Accept-Language': localStorage.getItem('lang') ? JSON.parse(localStorage.getItem('lang')).value : 'ar',
    'Content-Language': localStorage.getItem('lang') ? JSON.parse(localStorage.getItem('lang')).value : 'ar'
  }
}
export const apiResponseCatch = (error) => {
  if (error?.response?.data?.errors && JSON.stringify(error?.response?.data?.errors) !== '{}') {
    // eslint-disable-next-line no-prototype-builtins
    if (error.response.data.errors?.hasOwnProperty('translations')) {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(error.response.data.errors.translations)) {
        core.showSnackbar('error', value)
      }
    } else {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(error.response.data.errors)) {
        core.showSnackbar('error', value)
      }
    }
  } else if (error?.response?.data) {
    core.showSnackbar('error', error.response.data.message)
  }
  return Promise.reject(error)
}
export function singleSignOn () {
  const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_LINK,
    headers: apiHeader()
  })
  apiClient.interceptors.response.use((response) => {
    return response
  }, apiResponseCatch)
  return apiClient
}
export function marketPlace () {
  const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_LINK,
    headers: apiHeader()
  })
  apiClient.interceptors.response.use((response) => {
    return response
  }, apiResponseCatch)
  return apiClient
}
export function dashBoard () {
  const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_LINK,
    headers: apiHeader()
  })
  apiClient.interceptors.response.use((response) => {
    return response
  }, apiResponseCatch)
  return apiClient
}
export function website () {
  const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_LINK,
    headers: apiHeader()
  })
  apiClient.interceptors.response.use((response) => {
    return response
  }, apiResponseCatch)
  return apiClient
}
