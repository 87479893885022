export default {
  dashboard: 'Dashboard',
  profits: 'Profits',
  expectedProfits: 'Expected profits',
  retainedProfits: 'Retained profits',
  withdrawableProfits: 'Withdrawable profits',
  totalIncome: 'Total income',
  walletBalance: 'Wallet balance',
  totalOrders: 'Total orders',
  totalCategories: 'Total categories',
  totalProducts: 'Total products'
}
