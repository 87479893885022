import { apiHeader, apiResponseCatch, marketPlace, website } from '@/axios';
import axios from 'axios';

export default {
  listDataTabl (url, filter) {
    return marketPlace().get(url, {
      params: filter
    });
  },
  commonUploadFiles (payload, config, baseUrl = 'merchant/upload/default') {
    return website().post(baseUrl, payload, config);
  },
  removeImage (id, type) {
    return marketPlace().get(`remove-image/${ id }/${ type }`);
  },
  removeRow (url, rowId) {
    console.log(url, rowId);
    return marketPlace().delete(`${ url }/${ rowId }`);
  },
  changeStatus (baseURL = '', url, id, payload) {
    const apiClient = axios.create({
      baseURL: baseURL,
      headers: apiHeader()
    });
    apiClient.interceptors.response.use((response) => {
      return response;
    }, apiResponseCatch);
    return apiClient.patch(`${ url }/${ id }`, {
      status: payload
    });
  },
  getAllCities () {
    return marketPlace().get('cities');
  },
  getAllContries () {
    return marketPlace().get('countries');
  },
  getAllAreas (city_id) {
    return marketPlace().get(`areas?city_id=${ city_id }`);
  }
};
