// Layout
const dashboardLayout = () => import('@/layouts/DashboardLayout/dashboardLayout.vue')

const allOrders = () => import('./views/index')
const showOrder = () => import('./views/showOrder')
/* const addProduct = () => import('./views/addProduct') */

// start Routes
export default [
  {
    path: '/orders',
    name: 'orders',
    component: dashboardLayout,
    children: [{
      path: '',
      name: 'orders',
      meta: {
        name: 'orders',
        parentName: 'marketplace',
        userType: '',
        permission: 'orders:find'
      },
      component: allOrders
    },
    {
      path: 'show-order/:id',
      name: 'orders.showOrder',
      props: true,
      meta: {
        name: 'showOrder',
        parentName: 'marketplace',
        userType: '',
        permission: 'orders:findOne'
      },
      component: showOrder
    }
      /* {
      path: 'add-product',
      name: 'addProduct',
      meta: {
        name: 'addProduct',
        parentName: 'marketplace',
        userType: '',
        permission: ''
      },
      component: addProduct
    } */
    ]
  }
]
